import "./TripStyles.css";
import TripData from "./TripData";
import Trip1 from "../assets/images/IT.jpeg";
import Trip2 from "../assets/images/ELC.jpg";
import Trip3 from "../assets/images/CIVI.jpg";
import Trip4 from "../assets/images/MECH.jpg";
import Trip5 from "../assets/images/Chem.jpg";
import Trip6 from "../assets/images/MANG.jpg";
function Trip() {
  return (
    <div className="trip">
      <h1>
        <span>
        أقسام المعهد
        </span>
        </h1>
      <div className="tripcard">
        <TripData
        pimage={Trip1}
        heading="قسم تقنيات الحاسوب"
        text="مجموعة من المعرفة والمهارات المتعلقة بتصميم وتطوير البرمجيات والأجهزة الحاسوبية، بما في ذلك الشبكات وأنظمة التشغيل وقواعد البيانات والأمن السيبراني والذكاء الاصطناعي والواقع الافتراضي وغيرها. "
        />
            <TripData
        pimage={Trip2}
        heading="قسم التقنيات الكهربائية"
        text="إحدى فروع الهندسة الكهربائية التي تركز على تطبيق المبادئ والتقنيات الكهربائية في مجالات متعددة مثل الطاقة الكهربائية والاتصالات والإلكترونيات وأنظمة التحكم والأتمتة وغيرها. يشمل هذا القسم دراسة تصميم وتحليل وتنفيذ وصيانة الأنظمة والأجهزة الكهربائية والإلكترونية في مجالات مختلفة. يتم تدريس مواد متنوعة في قسم التقنيات الكهربائية مثل الدوائر الكهربائية، والمحركات الكهربائية، والإلكترونيات، وأنظمة الطاقة، والتحكم الآلي، والروبوتات، والاتصالات، والشبكات الكهربائية، والطاقة المتجددة، والذكاء الاصطناعي المتقدم، وغيرها. "
        />
            <TripData
        pimage={Trip3}
        heading="قسم التقنيات المدنيةوالمعمارية"
        text="قسم التقنيات الهندسية هو إحدى تخصصات الهندسة التي تركز على تطبيق المبادئ والتقنيات الهندسية في مجالات متنوعة مثل الإنشاءات والهندسة المعمارية والهندسة المدنية والهندسة الكهربائية والهندسة الميكانيكية والهندسة الصناعية وغيرها. يتناول هذا القسم دراسة التصميم والتحليل والتنفيذ والصيانة للأنظمة والهياكل والأجهزة التقنية في مختلف المجالات الهندسية."
        />
          <TripData
        pimage={Trip4}
        heading="قسم التقنيات الميكانيكة"
        text="إحدى الأقسام الرئيسية في كلية الهندسة أو الجامعة التقنية، ويتخصص في دراسة الهندسة الميكانيكية. يشمل هذا القسم دراسة مجموعة واسعة من المواضيع التي تتعلق بالتصميم والتحليل والتصنيع وتطوير الأنظمة والأجهزة الميكانيكية."
        />
            <TripData
        pimage={Trip5}
        heading="قسم التقنيات الكيميائية"
        text="جزء من كلية الهندسة أو العلوم التطبيقية في الجامعات والمؤسسات التعليمية. يهتم هذا القسم بدراسة التقنيات والعمليات التي تستخدم في تحويل المواد الكيميائية والمنتجات إلى منتجات نهائية مفيدة."
        />
              <TripData
        pimage={Trip6}
        heading="قسم الإدارة الهندسية"
        text="يتخصص في دمج المفاهيم الهندسية والإدارية لتحسين عمليات الإنتاج والخدمات في مجالات الهندسة المختلفة. يركز هذا القسم على تطوير المهارات اللازمة لإدارة المشاريع الهندسية بفعالية، بما في ذلك التخطيط والتنظيم والتوجيه والمراقبة."
        />
      </div>
    </div>
  );
}

export default Trip;
