import "./FooterStyles2.css";

const Footer2 = () => {
  return (
    <>
      <div class="footerContainer">
        <div class="socialIcons">
            <a href="https://www.facebook.com/%D8%B4%D8%A4%D9%88%D9%86-%D8%A7%D9%84%D8%B7%D9%84%D8%A8%D8%A9-%D8%A8%D8%A7%D9%84%D9%85%D8%B9%D9%87%D8%AF-%D8%A7%D9%84%D8%B9%D8%A7%D9%84%D9%8A-%D9%84%D9%84%D9%85%D9%87%D9%86-%D8%A7%D9%84%D9%87%D9%86%D8%AF%D8%B3%D9%8A%D8%A9-%D8%B2%D9%84%D9%8A%D8%AA%D9%86-818262531519484/" target="_blank" title="facebook" rel="noopener noreferrer"><i class="fa-brands fa-facebook"></i></a>
            <a href="/"><i class="fa-brands fa-instagram"></i></a>
            <a href="/"><i class="fa-brands fa-twitter"></i></a>
            <a href="/"><i class="fa-brands fa-google-plus"></i></a>
            <a href="/"><i class="fa-brands fa-youtube"></i></a>
        </div>
        <div class="footerNav">
            <ul><li><a href="mailto:info@hpiz.edu.ly ">info@hpiz.edu.ly</a></li>
                <li><a href="/">0514620055</a></li>
                <li><a href="/">0514620630</a></li>
                <li><a href="/">0514622058</a></li>
            </ul>
        </div>
        
    </div>
    <div class="footerBottom">
        <p>Copyright &copy;2024; Designed by Higher Institute of Engineering Technology - Zliten <span class="designer">HPIZ</span></p>
    </div>
    </>
  );
};

export default Footer2;
