import "./HeroStyles.css";
import { Link } from "react-router-dom";
function Hero(props) {
  return (
    <>
      <div className={props.cName}>
        <img alt="hpiz" src= {props.heroimage}/>
        <div className="hero-text">
        <h1>المعهد العالي للتقنيات الهندسية-زليتن</h1>
        <Link to="/contact" className={props.btnclass}>Contact Us</Link>
        </div>
      </div>
    </>
  );
}

export default Hero;
