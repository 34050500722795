export const MenuItems = [
  // Make a list of Navlinks & their properties.
  {
    title:"الرئيسية",
    url:"/",
    cName:"nav-links",
    icon:"fa-solid fa-house-user",
    targett:"_self"
  },
  {
    title:"عن المعهد",
    url:"#",
    cName:"nav-links",
    icon:"fa-solid fa-circle-info",
    targett:"_self"
  },
  {
    title:"الدراسة والامتحانات",
    url:"https://sms.arraly.com/zlhinst2/",
    cName:"nav-links",
    icon:"fa-solid fa-briefcase",
    targett:"_blank"
  },
  {
    title:"خدمات اخري",
    url:"#",
    cName:"nav-links",
    icon:"fa-solid fa-address-book",
    targett:"_self"
  },

];
