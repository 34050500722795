
import Navbar from "../components/Navbar";
import Footer from "../components/Footer2";
import ContactForm from "../components/ContactForm";

function Contact() {
  return (
    <>
      <Navbar/>
      <ContactForm/>
      <Footer/>
    </>
  );
}

export default Contact;
