let timelineElements = [
  {
    id: 1,
    title: "المجتمع",
    location: "Dragontail, Ascana",
    description:
      "دعم المجتمع بالكوادر  والكفاءات الفنية والمهنية المتخصصة في شتى المجالات.",
    buttonText: "View Frontend Projects",
    date: "August 2016 - present",
    icon: "work",
  },
  {
    id: 2,
    title: "المساهمة",
    location: "Skystead, Craonia",
    description:
      "المساهمة في إعداد وتنفيذ البرامج  التدريبية للرفع من كفاءة العاملين بالوحدات الإدارية والإنتاجية والخدمية في  المجتمع",
    buttonText: "View Backend Projects",
    date: "June 2013 - August 2016",
    icon: "work",
  },
  {
    id: 3,
    title: "الدراسات العلمية",
    location: "Skystead, Craonia",
    description:
      "القيام بالدراسات العلمية  والعملية التطبيقية للمساهمة في خدمة  كافة المؤسسات ذات العلاقة باختصاصات المعهد  والعمل على تطويرها وحل  مشكلاتها",
    buttonText: "View Backend Projects",
    date: "June 2013 - August 2016",
    icon: "work",
  },
  {
    id: 4,
    title: "التوظيف",
    location: "Skystead, Craonia",
    description:
      "توظيف التقنيات الحديثة واستثمارها  الاستثمار الأمثل وتعميمها على كافة جهات المجتمع للإستفاذة منها",
    buttonText: "View Backend Projects",
    date: "June 2013 - August 2016",
    icon: "work",
  },
  {
    id: 5,
    title: "التعاون",
    location: "Skystead, Craonia",
    description:
      "التعاون العلمي  وتبادل الخبرات مع المؤسسات  العلمية والاستشارات العلمية والتقنية المتعلقة لتطوير  أساليب العمل  بالمؤسسات العامة والخاصة",
    buttonText: "View Backend Projects",
    date: "June 2013 - August 2016",
    icon: "work",
  },
  {
    id: 6,
    title: "تنظيم المؤتمرات",
    location: "Skystead, Craonia",
    description:
      "تنظيم المؤتمرات والندوات  واللقاءات ذات العلاقة باختصاصات المعهد والتي تساهم في تطوير والمجتمع والدفع به  نحو الأمام",
    buttonText: "View Backend Projects",
    date: "June 2013 - August 2016",
    icon: "work",
  },
  
];

export default timelineElements;
