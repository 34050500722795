import Person1 from "../assets/images/p1.jpg";
import Person2 from "../assets/images/p2.jpg";
import Person3 from "../assets/images/p3.jpg";
const people = [
  {
    id: 1,
    image:  Person1,
    name: "د.عصام أبوحجر",
    title: "مدير عام المعهد",
    quote:
      "وضع الأهداف والتوجيهات العامة للمعهد وتطوير الخطط لتحقيقها, وأيضا توظيف وتدريب وتوجيه أعضاء هيئة التدريس والموظفين الإداريين، وتنظيم الفعاليات التدريبية وإدارة الأداء.",
  },
  {
    id: 2,
    image: Person2,
    name: "أ.محمد خوجة",
    title: "مكتب الشؤون العلمية",
    quote:
"تصميم وتحديث البرامج الدراسية والمناهج الأكاديمية بما يتناسب مع احتياجات سوق العمل وتطلعات الطلاب والمجتمع , مراقبة جودة الأداء الأكاديمي وتقييم البرامج والأنشطة العلمية وتطبيق التحسينات اللازمة.",
  },
  {
    id: 3,
    image: Person3,
    name: "د.خالد بن عمران",
    title: "مكتب أعضاء هيئة التدريس",
    quote:
      "مكتب أعضاء هيئة التدريس في المعهد العالي يعمل على توفير الدعم الإداري والإداري لأعضاء هيئة التدريس وضمان سير العمل الأكاديمي بفاعلية وكفاءة.",
  },

];

export default people;
