import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Trip from "../components/Trip";
import Slider from "../components/Slider";
import TimeLine from "../components/TimeLine";
import Footer2 from "../components/Footer2";
import Timage from "../assets/images/hpiz-img.jpg";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      setLoading(false);
  }, []);

  return (
    <>
  
      {loading ? (
        <>
          <Skeleton height={100} /> {/* Skeleton for Navbar */}
          <Skeleton height={400} width={600} /> {/* Skeleton for Hero */}
          <Skeleton height={200} width="100%" /> {/* Skeleton for Trip */}
          <Skeleton height={300} width="100%" /> {/* Skeleton for Slider */}
          <Skeleton height={200} width="100%" /> {/* Skeleton for TimeLine */}
          <Skeleton height={100} /> {/* Skeleton for Footer */}
        </>
      ) : (
        <>
          <Navbar />
          <Hero cName="hero" heroimage={Timage} btnclass="show" />
          <Trip />
          <Slider />
          <TimeLine />
          <Footer2 />
        </>
      )}
    </>
  );
}

export default Home;
