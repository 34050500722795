import { Component } from "react";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems";
import Logo from "../assets/images/logo.png";
class Navbar extends Component {
  // Set state
  // Make Handleclick Function
   state={clicked:false};
   handleclick=()=>{
    this.setState({clicked:!this.state.clicked})

   }
  render() {
    return (
      <nav className="NavbarItems">
        <div class="navbar-logo">
       <img src={Logo} alt="Logo"/>

        </div>
        <div className="menu-icons" onClick={this.handleclick}>
        <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
        
            {MenuItems.map((item,index)=>{
              return(
                <li key={index}>
                <a href={item.url} className={item.cName} target={item.targett}><i class={item.icon}></i> {item.title}</a>
                </li>
                
              );
            })}
            
            
        </ul>
      </nav>
    );
  }
}

export default Navbar;
