import React from 'react'
import { FaAward } from "react-icons/fa";
import timelineElements from "../timelineElements";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "./TimeLinestyles.css";
import "react-vertical-timeline-component/style.min.css";
function TimeLine() {
  let workIconStyles = { background: "rgb(204, 179, 124)" };
  let schoolIconStyles = { background: "rgb(204, 179, 124)" };
    return (
      <div dir='ltr'>
        <h1 className="title">أهداف المعهد</h1>
        <VerticalTimeline lineColor='rgb(204, 179, 124)'>
        {timelineElements.map((element) => {
          let isWorkIcon = element.icon === "work";

          return (
            <VerticalTimelineElement
              key={element.key}
              iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
              icon={<FaAward/>}
              contentArrowStyle={{borderRight:"5px solid rgb(204, 179, 124)"}}
            >
              <h3 className="vertical-timeline-element-title titlee">
                {element.title}
              </h3>
              <p id="description">{element.description}</p>
            </VerticalTimelineElement>
          );
        })}
        </VerticalTimeline>


      </div>
  )
}

export default TimeLine